import React from "react"

class AboutSVG extends React.Component {
  constructor() {
    super()
    this.state = { loading: true }
  }

  componentWillMount() {
    this.setState({ loading: false })
  }

  render() {
    return (
      <svg width="309px" height="274px" viewBox="0 0 309 274" version="1.1">
        <g
          id="Architecture"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="portrait"
            transform="translate(3.000000, 1.000000)"
            stroke="#000000"
            stroke-width="2"
          >
            <path
              id="Shape"
              d="M1.51174168 0 1.20939335 4.82819383 0.90704501 9.65638767 4.83757339 14.4845815 6.65166341 17.2004405"
            ></path>
            <path
              id="Shape"
              d="M11.1868885 20.8215859 13.9080235 16.5969163 16.0244618 12.0704846 14.2103718 9.05286344 14.8150685 3.92290749 17.2338552 3.01762115 14.8150685 4.2246696"
            ></path>
            <path
              id="Shape"
              d="M18.4432485 0.603524229 17.2338552 5.73348018 20.8620352 11.7687225 21.7690802 16.5969163 20.5596869 21.123348 19.3502935 25.6497797 19.9549902 30.4779736 19.0479452 35.6079295 17.8385519 40.4361233 23.2808219 37.4185022"
            ></path>
            <path
              id="Shape"
              d="M24.4902153 35.9096916 24.4902153 41.0396476 25.3972603 45.8678414"
            ></path>
            <path
              id="Shape"
              d="M32.0489237 45.5660793 36.8864971 42.5484581 39.3052838 46.7731278 41.1193738 50.3942731 44.4452055 44.9625551"
            ></path>
            <path
              d="M54.7250489,30.4779736 L59.5626223,33.4955947"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M61.981409 39.8325991 59.5626223 44.660793 61.981409 50.092511 59.8649706 55.222467"
            ></path>
            <path
              id="Shape"
              d="M58.6555773 65.1806167 61.3767123 68.1982379 65.0048924 73.6299559"
            ></path>
            <path
              id="Shape"
              d="M70.1448141 69.1035242 70.1448141 73.9317181 71.0518591 78.7599119 71.6565558 83.8898678 70.7495108 88.7180617 69.2377691 93.5462555 67.4236791 101.090308"
            ></path>
            <path
              id="Shape"
              d="M72.5636008 101.39207 74.3776908 96.5638767 72.2612524 97.1674009"
            ></path>
            <path
              d="M77.7035225,98.3744493 L76.1917808,103.202643"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M78.9129159 105.314978 75.5870841 99.8832599 76.1917808 99.2797357 78.9129159 94.4515419 78.9129159 89.623348 77.7035225 84.7951542 75.5870841 80.2687225 75.5870841 75.1387665 76.4941292 70.0088106 77.7035225 65.1806167 79.5176125 60.3524229 81.3317025 55.5242291 79.5176125 50.6960352 80.7270059 45.8678414 74.0753425 42.8502203"
            ></path>
            <path
              d="M75.2847358,45.5660793 L80.1223092,44.9625551"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M74.3776908 48.5837004 74.9823875 46.4713656 70.7495108 44.3590308 72.2612524 49.4889868"
            ></path>
            <path
              d="M70.4471624,50.6960352 L69.5401174,45.8678414"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M68.6330724 44.9625551 67.4236791 49.7907489 69.8424658 54.3171806 73.4706458 59.1453744 66.2142857 64.2753304"
            ></path>
            <path
              id="Shape"
              d="M71.3542074 64.2753304 68.3307241 69.1035242 68.6330724 73.9317181 67.1213307 78.4581498"
            ></path>
            <path
              id="Shape"
              d="M71.6565558 79.3634361 76.7964775 85.3986784 76.7964775 86.6057269"
            ></path>
            <path
              id="Shape"
              d="M79.5176125 96.5638767 78.9129159 101.693833 80.4246575 101.995595 80.4246575 106.220264 82.5410959 110.746696 83.7504892 110.746696 84.9598826 111.651982 90.0998043 115.273128"
            ></path>
            <path
              id="Shape"
              d="M87.9833659 109.539648 89.797456 108.332599 88.5880626 111.35022 91.0068493 112.557269 89.4951076 117.0837 91.9138943 121.911894 94.332681 126.740088 94.9373777 131.870044 97.053816 137 98.2632094 142.129956 97.9608611 146.95815 99.4726027 151.786344 100.077299 156.312775 100.984344 156.312775 102.193738 164.15859 102.798434 168.986784 104.007828 173.814978 104.310176 179.85022 106.124266 182.264317 107.333659 186.790749 110.357143 191.618943 112.77593 195.843612 116.40411 200.671806 118.520548 205.198238 122.148728 210.026432 125.47456 214.251101 131.521526 218.174009 129.10274 220.889868 128.498043 226.92511"
            ></path>
            <path
              d="M124.567515,223.605727 L119.729941,220.588106"
              id="Shape"
            ></path>
            <path
              d="M136.3591,225.4163 L141.80137,230.244493"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M148.453033 229.942731 141.80137 225.718062 139.684932 223.303965 143.313112 218.174009 144.220157 219.381057"
            ></path>
            <path
              d="M157.523483,223.907489 L162.361057,228.735683"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M165.989237 229.037445 160.244618 228.735683 159.337573 229.942731 159.94227 225.4163"
            ></path>
            <path
              d="M173.850294,221.795154 L178.687867,223.002203"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M188.363014 229.339207 188.363014 234.167401 188.665362 238.693833"
            ></path>
            <path
              id="Shape"
              d="M183.223092 237.486784 185.037182 232.65859 188.363014 228.132159 190.7818 222.096916 191.386497 217.872247 194.107632 214.251101 192.898239 209.72467 192.898239 209.72467"
            ></path>
            <path
              id="Shape"
              d="M194.712329 203.689427 199.247554 205.5 204.387476 203.387665"
            ></path>
            <path
              id="Shape"
              d="M208.318004 205.198238 212.550881 207.612335 214.062622 212.742291"
            ></path>
            <path
              id="Shape"
              d="M222.226027 211.23348 221.318982 206.405286 217.993151 204.594714 212.550881 200.068282 212.550881 195.240088 212.853229 190.411894 212.853229 185.5837 212.853229 180.755507 212.550881 176.229075 211.946184 171.702643 217.388454 166.874449 216.783757 164.15859 216.179061 159.330396 217.993151 159.028634 213.760274 154.200441"
            ></path>
            <path
              id="Shape"
              d="M218.900196 144.544053 221.016634 149.372247 221.621331 154.502203 221.923679 159.330396 222.528376 164.15859 224.342466 168.986784 225.551859 174.11674 226.156556 178.643172"
            ></path>
            <path
              id="Shape"
              d="M220.714286 177.134361 221.621331 181.962555 223.435421 186.488987 224.644814 191.317181 226.761252 196.447137 229.180039 201.27533 227.970646 206.103524 227.970646 210.629956 229.180039 215.45815 229.180039 220.286344"
            ></path>
            <path
              id="Shape"
              d="M232.808219 224.209251 227.365949 219.079295 226.458904 214.251101 225.854207 209.121145 226.156556 203.991189 226.761252 194.938326"
            ></path>
            <path
              d="M219.504892,196.447137 L219.807241,201.577093"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M218.900196 208.215859 217.086106 200.973568 217.388454 195.843612 214.969667 193.429515 214.969667 188.299559"
            ></path>
            <path
              id="Shape"
              d="M207.713307 181.660793 208.922701 186.488987 209.225049 191.317181 209.225049 195.843612 209.225049 200.671806 207.713307 205.5 211.039139 210.328194"
            ></path>
            <path
              id="Shape"
              d="M220.411937 216.363436 215.574364 211.837004 215.574364 207.008811 214.364971 199.162996 214.364971 194.334802 213.760274 189.506608 215.574364 181.962555 214.969667 177.134361 214.062622 172.306167 215.272016 169.89207 208.620352 177.134361 202.875734 182.264317 205.899217 175.927313 206.806262 171.099119 206.201566 172.004405"
            ></path>
            <path
              id="Shape"
              d="M215.876712 171.702643 217.690802 176.832599 217.993151 181.359031"
            ></path>
            <path
              id="Shape"
              d="M219.504892 174.720264 219.202544 179.85022 220.411937 184.678414 221.621331 189.80837 222.528376 194.938326 223.737769 199.76652 223.737769 204.594714 223.435421 209.422907"
            ></path>
            <path
              id="Shape"
              d="M202.271037 209.72467 201.363992 204.896476 201.968689 199.76652 198.945205 198.861233 194.40998 200.068282 194.40998 203.085903"
            ></path>
            <path
              id="Shape"
              d="M191.386497 216.363436 195.014677 218.777533 195.317025 223.605727 197.735812 228.433921 200.154599 232.960352 201.363992 237.788546 203.782779 242.314978 204.085127 246.84141 205.899217 251.669604 202.573386 259.515419 204.689824 258.30837 209.527397 263.740088 214.364971 269.171806"
            ></path>
            <path
              id="Shape"
              d="M223.435421 262.53304 228.272994 263.438326 230.691781 263.438326"
            ></path>
            <path
              id="Shape"
              d="M219.504892 247.143172 214.667319 241.409692 209.829746 236.279736 204.992172 233.865639"
            ></path>
            <path
              id="Shape"
              d="M204.992172 232.65859 211.643836 237.486784 214.969667 242.314978 218.597847 247.143172 222.528376 251.971366 223.737769 256.799559 225.551859 259.213656 230.994129 264.04185 234.924658 268.870044"
            ></path>
            <path
              id="Shape"
              d="M209.527397 246.539648 206.503914 242.013216 204.689824 237.185022 202.875734 232.055066 200.759295 227.528634 197.131115 222.700441 191.386497 218.777533"
            ></path>
            <path
              d="M173.245597,208.819383 L168.408023,208.819383"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M167.803327 205.198238 172.6409 203.991189 177.780822 202.180617 182.013699 204.594714 177.176125 207.612335 183.52544 203.689427"
            ></path>
            <path
              id="Shape"
              d="M170.524462 203.689427 168.105675 208.517621 171.129159 211.535242"
            ></path>
            <path
              d="M173.245597,192.524229 L168.105675,191.920705"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M182.013699 190.713656 183.827789 186.187225 188.060665 182.264317 194.107632 177.436123 195.014677 171.099119 187.758317 166.270925 183.827789 165.063877 185.33953 160.235683 185.33953 164.460352"
            ></path>
            <path
              id="Shape"
              d="M186.246575 164.762115 190.7818 167.176211 195.619374 171.400881"
            ></path>
            <path
              id="Shape"
              d="M195.619374 166.874449 190.7818 165.365639 185.944227 162.64978"
            ></path>
            <path
              id="Shape"
              d="M184.734834 173.814978 189.572407 174.720264 194.40998 174.11674"
            ></path>
            <path
              id="Shape"
              d="M192.898239 178.34141 188.060665 181.359031 183.52544 183.169604 178.990215 183.169604 174.152642 183.773128 168.710372 184.376652 163.268102 184.678414 158.430528 184.678414 153.592955 186.187225 148.453033 183.773128"
            ></path>
            <path
              id="Shape"
              d="M145.42955 182.566079 150.267123 182.566079 155.104697 180.151982 154.197652 179.85022 150.267123 177.737885 155.104697 171.702643"
            ></path>
            <path
              id="Shape"
              d="M155.709393 168.38326 152.081213 173.211454 152.68591 174.720264 147.848337 180.453744 145.731898 182.867841 153.592955 187.696035 159.035225 193.429515 162.058708 194.03304 166.896282 194.03304 171.733855 194.636564 176.571429 194.03304 181.409002 191.618943 186.548924 188.299559"
            ></path>
            <path
              d="M194.40998,183.169604 L199.247554,183.773128"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M192.293542 180.453744 190.479452 185.5837 189.270059 190.411894 187.758317 195.240088 186.851272 195.54185"
            ></path>
            <path
              id="Shape"
              d="M171.431507 171.702643 176.26908 168.685022 181.409002 168.38326 182.920744 163.856828 187.758317 158.726872"
            ></path>
            <path
              id="Shape"
              d="M186.548924 138.508811 191.386497 144.544053 196.526419 146.354626 201.363992 146.95815 206.201566 146.052863 211.039139 144.845815 216.179061 141.22467 221.318982 135.189427 229.784736 127.947137 228.575342 133.077093 229.482387 137.905286 229.482387 142.73348 231.598826 149.674009"
            ></path>
            <path
              id="Shape"
              d="M230.087084 134.887665 230.691781 131.26652 232.203523 127.947137 236.134051 124.325991 240.971624 126.438326 243.692759 128.550661"
            ></path>
            <path
              id="Shape"
              d="M247.623288 138.810573 252.158513 138.207048 253.367906 144.544053 261.228963 143.337004 258.507828 148.165198 254.274951 145.147577 259.112524 142.129956"
            ></path>
            <path
              id="Shape"
              d="M256.996086 149.975771 261.833659 149.975771 266.671233 149.372247"
            ></path>
            <path
              id="Shape"
              d="M270.299413 151.786344 267.27593 149.674009 266.671233 143.940529 268.787671 143.337004 273.625245 140.017621 271.508806 136.094714 267.27593 127.645374 270.299413 124.024229 267.880626 118.592511 267.578278 113.764317 264.554795 113.764317 263.345401 113.160793 261.531311 108.634361 263.345401 103.202643 258.810176 102.599119 256.693738 100.486784 253.670254 96.5638767 250.646771 92.0374449 249.437378 94.753304 245.506849 92.0374449 251.251468 89.623348 245.809198 89.9251101"
            ></path>
            <path
              id="Shape"
              d="M245.506849 81.4757709 243.088063 86.3039648 240.064579 94.4515419 232.203523 100.486784 230.389432 105.61674 232.505871 110.444934 231.901174 115.273128 228.272994 120.101322 226.458904 125.231278 225.854207 130.361233 226.458904 134.887665 227.063601 140.017621 227.668297 144.544053 225.854207 149.372247 226.156556 154.200441 226.156556 158.726872 226.761252 163.555066 227.365949 168.38326 228.575342 173.211454 229.482387 177.737885 230.087084 182.264317 228.272994 187.394273 229.784736 192.222467 229.482387 197.352423 229.482387 202.180617 230.691781 205.801762 231.598826 210.629956 232.203523 215.759912 232.203523 220.588106 234.924658 224.812775 237.948141 223.605727 242.785714 228.735683 247.925636 231.753304 252.763209 235.977974 257.600783 242.314978 262.740705 244.729075 267.578278 245.936123 272.113503 244.729075 276.951076 244.729075 281.78865 247.143172"
            ></path>
            <path
              id="Shape"
              d="M277.555773 243.823789 282.393346 248.651982 283.905088 253.781938 285.114481 258.610132 285.114481 263.438326 285.719178 268.568282 286.323875 273.094714"
            ></path>
            <path
              id="Shape"
              d="M287.533268 261.024229 292.068493 260.722467 296.906067 260.118943 301.74364 259.817181"
            ></path>
            <path
              id="Shape"
              d="M297.813112 261.325991 302.650685 263.136564 307.488258 264.343612"
            ></path>
            <path
              id="Shape"
              d="M304.162427 252.273128 302.650685 252.273128 303.255382 247.746696 308.697652 246.539648 308.697652 244.125551 303.860078 244.729075"
            ></path>
            <path
              id="Shape"
              d="M296.30137 243.220264 299.92955 247.746696 301.138943 259.213656 302.953033 263.438326"
            ></path>
            <path
              d="M299.022505,256.497797 L294.184932,255.592511"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M294.48728 254.0837 299.627202 253.480176 300.231898 254.687225 305.069472 257.101322"
            ></path>
            <path
              id="Shape"
              d="M206.201566 254.988987 201.363992 251.971366 196.22407 251.669604 191.688845 257.704846 186.851272 262.53304 180.804305 267.361233 175.664384 273.698238"
            ></path>
            <path
              id="Shape"
              d="M182.316047 270.378855 187.15362 265.550661 193.502935 260.722467"
            ></path>
            <path
              id="Shape"
              d="M200.456947 261.929515 195.619374 261.325991 190.7818 264.343612 186.246575 267.361233 181.409002 272.189427 178.687867 272.189427 177.478474 270.378855 181.106654 265.248899 189.572407 260.118943 193.502935 255.592511 196.526419 250.764317"
            ></path>
            <path
              id="Shape"
              d="M198.03816 250.462555 204.085127 255.290749 205.899217 260.722467 210.132094 265.852423 213.457926 270.378855 215.876712 271.585903"
            ></path>
            <path
              id="Shape"
              d="M152.081213 271.284141 146.034247 268.26652 141.196673 267.662996 133.335616 265.550661 128.498043 265.550661 125.47456 270.378855 120.636986 263.740088 118.520548 260.722467 113.682975 260.118943 107.636008 262.231278 102.798434 264.645374 97.6585127 269.171806 94.6350294 269.77533 100.077299 267.361233 105.217221 261.929515 108.240705 259.817181 107.031311 254.385463 106.426614 251.971366 99.1702544 249.255507 98.5655577 244.427313 95.239726 242.013216 91.0068493 238.39207 90.4021526 232.960352 97.053816 228.132159 100.984344 223.303965 102.193738 218.174009 103.705479 213.345815 103.403131 208.517621 103.705479 203.991189 103.705479 199.464758 103.705479 194.334802 104.007828 189.506608 102.193738 185.885463 97.9608611 180.755507 93.425636 175.927313 93.425636 171.099119 89.1927593 169.590308"
            ></path>
            <path
              id="Shape"
              d="M86.1692759 169.288546 86.4716243 174.11674 87.6810176 178.643172 89.1927593 183.471366"
            ></path>
            <path
              id="Shape"
              d="M88.2857143 178.643172 88.890411 170.495595 91.0068493 167.779736 91.9138943 162.951542 89.4951076 161.140969"
            ></path>
            <path
              id="Shape"
              d="M87.0763209 166.572687 88.2857143 163.555066 85.2622309 165.365639 83.7504892 170.495595 83.7504892 175.323789 85.8669276 180.453744 87.6810176 185.281938"
            ></path>
            <path
              id="Shape"
              d="M96.146771 187.696035 97.3561644 192.825991 99.1702544 193.429515 105.217221 189.506608 110.357143 197.352423 115.194716 203.991189 119.729941 209.121145 123.66047 215.45815 129.10274 219.682819 132.73092 223.303965 137.870841 226.019824 142.708415 230.244493 148.150685 232.960352 152.988258 234.770925 157.825832 235.072687 162.663405 235.072687 167.500978 234.770925 172.338552 234.770925 176.873777 234.167401 181.71135 231.753304 186.548924 230.546256 189.572407 225.4163 197.433464 223.002203 201.968689 231.753304 206.806262 242.61674 208.922701 246.237885"
            ></path>
            <path
              id="Shape"
              d="M150.569472 268.870044 145.731898 266.757709 140.591977 265.550661 135.754403 266.757709 132.428571 273.094714 134.54501 268.26652"
            ></path>
            <path
              d="M139.080235,267.059471 L143.313112,271.887665"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M123.66047 274 126.683953 269.171806 125.172211 264.343612 123.055773 264.343612"
            ></path>
            <path
              id="Shape"
              d="M119.125245 273.698238 113.985323 270.077093 111.868885 271.284141 109.450098 269.77533 107.031311 270.378855 107.031311 272.189427 110.054795 273.094714 110.659491 268.26652"
            ></path>
            <path
              id="Shape"
              d="M109.752446 263.136564 108.240705 267.964758 104.007828 266.154185 99.7749511 272.189427 102.496086 270.680617 103.705479 268.870044 106.124266 264.04185 113.078278 261.627753 116.101761 265.852423"
            ></path>
            <path
              d="M79.2152642,264.645374 L74.6800391,258.006608"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M72.5636008 259.515419 70.7495108 263.438326 71.9589041 264.645374"
            ></path>
            <path
              id="Shape"
              d="M87.3786693 269.171806 86.7739726 268.870044 83.1457926 267.662996 78.9129159 268.870044 77.7035225 273.396476 78.3082192 268.568282 77.4011742 263.438326 74.3776908 261.325991 75.8894325 255.290749 75.2847358 249.557269 74.6800391 244.729075 75.2847358 238.995595 74.0753425 233.262115 72.2612524 241.711454"
            ></path>
            <path
              id="Shape"
              d="M77.0988258 219.682819 81.9363992 225.4163 86.7739726 232.65859 91.611546 231.451542 96.4491194 228.132159 101.286693 222.096916"
            ></path>
            <path
              id="Shape"
              d="M90.704501 213.345815 90.0998043 211.535242 85.2622309 213.345815 80.4246575 215.759912 76.4941292 215.156388 70.4471624 218.174009 65.9119374 220.588106 60.7720157 217.570485 61.3767123 212.742291 62.888454 207.914097 61.6790607 201.27533 62.5861057 196.447137 61.074364 191.618943 60.4696673 186.790749 61.3767123 181.962555 60.7720157 177.134361 61.3767123 171.099119 59.5626223 166.572687 62.2837573 161.744493 62.5861057 156.9163 66.2142857 152.088106 70.7495108 151.484581 70.1448141 146.95815 71.6565558 142.129956 72.8659491 137.301762 75.2847358 132.473568 73.4706458 127.645374 73.4706458 122.515419 73.4706458 117.385463 73.1682975 112.557269 71.6565558 109.237885 69.8424658 104.10793 72.2612524 100.185022 71.0518591 99.5814978 70.7495108 94.4515419 69.5401174 88.4162996 70.1448141 83.8898678 65.609589 79.061674 66.8189824 71.5176211 66.2142857 66.6894273 67.7260274 64.2753304 71.6565558 63.6718062 70.4471624 58.8436123 76.7964775 56.4295154 75.5870841 51.9030837 78.6105675 47.0748899 78.0058708 42.246696 82.2387476 39.8325991 83.4481409 35.3061674 86.7739726 31.685022 86.1692759 26.8568282 83.7504892 19.0110132 82.5410959 14.1828194 82.2387476 9.35462555 79.8199609 1.50881057 74.9823875 3.92290749"
            ></path>
            <path
              id="Shape"
              d="M74.3776908 1.50881057 74.3776908 6.03524229 74.9823875 10.561674 75.8894325 15.3898678 78.0058708 20.2180617 76.7964775 22.9339207 81.3317025 26.5550661 84.3551859 31.9867841 87.9833659 32.2885463 90.704501 28.6674009 92.8209393 25.3480176 96.146771 31.685022"
            ></path>
            <path
              id="Shape"
              d="M100.077299 20.2180617 104.914873 26.253304 109.752446 25.0462555"
            ></path>
            <path
              id="Shape"
              d="M110.96184 22.9339207 110.96184 16.8986784 105.821918 15.0881057 105.217221 10.561674 107.333659 5.43171806 106.124266 3.62114537 101.286693 1.81057269"
            ></path>
            <path
              id="Shape"
              d="M98.2632094 1.81057269 103.100783 7.54405286 106.124266 11.7687225 109.14775 8.44933921 109.14775 13.8810573 109.450098 18.1057269"
            ></path>
            <path
              id="Shape"
              d="M112.171233 10.561674 113.985323 3.31938326 106.426614 2.41409692 102.193738 10.2599119 99.7749511 9.05286344 98.2632094 8.14757709 98.2632094 5.43171806"
            ></path>
            <path
              id="Shape"
              d="M95.8444227 7.54405286 98.5655577 3.92290749 95.5420744 7.54405286 97.9608611 12.0704846 102.193738 15.69163 106.728963 20.2180617"
            ></path>
            <path
              d="M101.286693,24.7444934 L96.7514677,16.8986784"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M96.4491194 15.69163 101.286693 21.4251101 105.519569 26.253304"
            ></path>
            <path
              id="Shape"
              d="M95.239726 36.814978 90.4021526 40.7378855 92.2162427 38.9273128 95.8444227 33.7973568 97.9608611 29.2709251 98.2632094 24.4427313 93.1232877 23.2356828 96.7514677 21.123348 94.6350294 16.2951542 91.9138943 11.1651982 89.1927593 7.54405286 88.5880626 4.2246696 90.0998043 3.92290749 88.2857143 1.50881057"
            ></path>
            <path
              id="Shape"
              d="M94.332681 2.71585903 92.2162427 2.71585903 92.8209393 7.54405286 95.8444227 12.3722467 96.4491194 16.8986784 98.2632094 22.0286344 101.891389 27.4603524"
            ></path>
            <path
              id="Shape"
              d="M87.6810176 32.8920705 88.2857143 28.0638767 83.7504892 25.0462555"
            ></path>
            <path
              d="M82.2387476,37.1167401 L87.0763209,41.9449339"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M106.426614 55.222467 106.426614 57.0330396 106.426614 61.8612335 106.426614 66.3876652 103.403131 71.215859 101.891389 75.7422907 100.379648 78.1563877"
            ></path>
            <path
              id="Shape"
              d="M103.100783 79.3634361 107.938356 71.5176211 110.054795 69.7070485 105.821918 74.5352423 103.100783 79.3634361 101.891389 84.19163 100.681996 89.0198238 99.4726027 93.8480176"
            ></path>
            <path
              id="Shape"
              d="M98.5655577 98.0726872 100.984344 88.4162996 106.124266 82.9845815"
            ></path>
            <path
              id="Shape"
              d="M107.636008 80.8722467 104.612524 86.0022026 106.728963 86.6057269 103.100783 92.6409692 100.379648 97.469163 100.984344 100.185022 98.2632094 105.013216 98.2632094 109.84141 99.4726027 113.764317 105.519569 114.367841 110.357143 121.610132 112.77593 126.438326 113.682975 131.26652 115.497065 136.094714 117.613503 141.22467 120.636986 146.052863 126.683953 150.881057"
            ></path>
            <path
              id="Shape"
              d="M116.706458 133.982379 121.544031 143.638767 126.381605 147.863436 131.219178 149.674009 136.056751 150.579295 140.894325 150.579295 145.731898 149.372247 150.267123 147.561674 155.104697 143.940529 159.94227 137.905286"
            ></path>
            <path
              id="Shape"
              d="M167.19863 129.757709 162.663405 138.810573 157.523483 145.449339 152.383562 148.46696 147.545988 150.881057 142.406067 151.786344 137.568493 152.389868 132.73092 151.786344 128.195695 150.277533 123.358121 147.561674 118.822896 143.638767 113.682975 133.378855"
            ></path>
            <path
              d="M104.914873,117.385463 L100.077299,114.669604"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M98.8679061 114.367841 98.2632094 117.687225 97.9608611 122.213656 96.7514677 127.04185 97.9608611 131.870044 98.8679061 136.396476 99.1702544 141.22467 100.984344 146.052863 103.403131 150.881057 105.217221 155.709251"
            ></path>
            <path
              id="Shape"
              d="M93.1232877 168.081498 97.3561644 167.477974 98.5655577 172.004405 99.7749511 176.530837"
            ></path>
            <path
              id="Shape"
              d="M92.2162427 174.11674 97.053816 180.755507 102.193738 186.187225"
            ></path>
            <path
              id="Shape"
              d="M105.821918 189.204846 107.636008 194.03304 110.659491 198.861233 113.682975 203.387665 118.2182 208.215859 119.125245 210.026432 121.241683 214.854626 124.265166 219.682819 119.427593 220.889868 124.869863 225.718062"
            ></path>
            <path
              id="Shape"
              d="M139.382583 191.317181 136.963796 190.713656 136.963796 185.5837 137.568493 180.755507 136.963796 181.057269"
            ></path>
            <path
              id="Shape"
              d="M143.313112 186.187225 148.150685 188.601322 152.988258 189.204846 157.523483 190.411894 162.361057 188.601322 167.500978 190.713656 172.338552 189.506608 177.176125 190.713656 182.013699 188.299559 186.851272 184.07489"
            ></path>
            <path
              d="M169.617417,161.744493 L165.082192,162.046256"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M165.686888 159.632159 174.757339 164.762115 169.01272 175.625551"
            ></path>
            <path
              id="Shape"
              d="M178.08317 176.832599 181.71135 174.720264 177.176125 177.436123 172.338552 177.134361 167.803327 175.323789 162.965753 175.927313 158.430528 176.530837"
            ></path>
            <path
              id="Shape"
              d="M145.42955 187.696035 150.267123 192.825991 154.802348 197.654185"
            ></path>
            <path
              id="Shape"
              d="M157.221135 199.162996 152.383562 194.938326 147.24364 190.110132"
            ></path>
            <path
              id="Shape"
              d="M114.892368 185.5837 117.008806 183.169604 114.59002 183.169604 110.659491 183.773128 114.287671 188.903084"
            ></path>
            <path
              d="M114.287671,187.092511 L119.125245,192.524229"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M81.3317025 204.594714 82.8434442 209.422907 81.6340509 214.251101 82.2387476 217.570485 76.7964775 219.984581 81.0293542 224.511013 84.6575342 229.339207 88.2857143 234.469163 90.704501 235.977974 95.5420744 240.806167 100.077299 246.237885 104.914873 249.255507 106.124266 255.592511"
            ></path>
            <path
              id="Shape"
              d="M144.824853 230.546256 145.127202 226.92511 144.522505 222.096916 147.545988 217.570485 146.336595 214.552863 145.127202 216.665198 140.591977 221.19163 136.056751 220.588106 132.428571 216.363436 138.777886 211.23348 139.080235 213.044053 134.242661 216.061674 133.033268 213.044053 127.893346 214.552863 123.055773 209.72467 117.915851 203.689427 113.682975 194.938326 109.450098 189.506608 104.612524 179.85022 101.589041 174.418502 100.984344 169.590308 99.1702544 164.762115 99.1702544 163.253304 97.9608611 158.42511 92.8209393 157.821586 83.4481409 152.69163 80.4246575 147.863436 77.7035225 143.035242 76.4941292 138.508811 77.7035225 132.171806 78.6105675 127.343612 79.8199609 122.515419 79.2152642 115.57489 74.3776908 114.367841"
            ></path>
            <path
              d="M79.5176125,110.444934 L86.1692759,115.273128"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M97.6585127 118.592511 102.496086 118.592511 107.333659 120.403084 112.473581 124.024229 120.334638 116.480176 125.47456 114.066079 130.312133 112.557269 135.149706 111.953744 140.289628 111.35022 144.824853 111.35022 149.964775 111.953744 154.5 113.160793 159.337573 115.57489 166.896282 121.610132 171.733855 120.101322 176.26908 119.799559"
            ></path>
            <path
              id="Shape"
              d="M175.059687 121.610132 178.385519 126.136564 181.409002 131.26652 183.223092 136.094714 185.641879 140.922907 188.665362 145.751101 191.084149 151.182819 192.59589 156.011013 188.060665 161.744493 191.688845 166.572687 198.642857 169.590308 200.154599 174.418502 200.154599 178.944934 198.642857 183.773128 203.480431 187.092511 206.201566 193.127753 206.503914 197.654185 205.596869 202.482379 205.899217 207.612335 211.946184 214.251101 218.597847 216.363436 223.133072 218.174009 230.087084 222.096916"
            ></path>
            <path
              id="Shape"
              d="M256.391389 240.806167 252.763209 235.977974 247.623288 231.14978 239.157534 226.623348 237.041096 221.795154 236.738748 216.96696 235.529354 212.138767 234.319961 207.310573 234.017613 205.5 232.505871 200.671806 232.505871 195.843612 232.808219 190.713656 230.994129 186.187225"
            ></path>
            <path
              d="M247.925636,150.277533 L249.739726,145.751101"
              id="Shape"
            ></path>
            <path
              d="M248.530333,140.621145 L253.367906,139.715859"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M262.136008 144.242291 256.089041 150.277533 251.856164 149.674009 250.042074 145.449339 245.809198 151.182819 240.366928 151.786344 235.831703 151.786344 229.784736 152.69163 229.482387 147.863436 231.296477 143.035242 231.296477 138.207048 230.389432 130.964758 234.622309 126.136564 238.250489 121.30837 233.715264 118.894273 230.087084 116.178414 221.621331 123.420705 221.318982 123.722467 221.318982 117.988987 221.318982 113.160793 218.597847 113.160793"
            ></path>
            <path
              id="Shape"
              d="M216.179061 114.066079 216.179061 118.894273 218.295499 119.196035 215.876712 124.024229"
            ></path>
            <path
              d="M204.387476,119.196035 L199.549902,116.178414"
              id="Shape"
            ></path>
            <path
              d="M199.247554,118.592511 L203.782779,121.610132"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M209.225049 122.515419 204.387476 116.178414 199.247554 114.971366 194.40998 113.764317"
            ></path>
            <path
              id="Shape"
              d="M197.433464 110.143172 189.572407 114.971366 192.898239 112.557269 197.735812 110.143172 202.271037 109.539648 207.108611 109.237885 211.946184 109.237885 216.783757 111.35022 221.621331 112.557269"
            ></path>
            <path
              id="Shape"
              d="M222.528376 113.462555 224.342466 118.290749 224.040117 123.118943 223.435421 127.947137 222.226027 132.77533 219.202544 137.603524 214.364971 142.431718 215.272016 149.070485 215.876712 153.596916 212.853229 158.42511 212.248532 163.253304 210.434442 168.081498 209.527397 172.909692 207.410959 177.737885 205.899217 182.566079 203.782779 187.394273 201.363992 192.524229 199.549902 197.352423 196.526419 202.482379 194.712329 204.594714 197.735812 208.517621 199.549902 211.535242 204.689824 212.138767"
            ></path>
            <path
              id="Shape"
              d="M187.758317 145.147577 182.618395 135.491189 178.990215 122.817181 174.152642 121.610132"
            ></path>
            <path
              id="Shape"
              d="M170.222114 119.196035 167.803327 124.024229 168.408023 129.154185 165.989237 134.284141 162.965753 139.414097 159.035225 144.544053 152.988258 149.070485"
            ></path>
            <path
              id="Shape"
              d="M155.709393 152.389868 156.31409 151.182819 155.709393 156.312775 154.197652 161.140969 159.337573 166.270925 151.174168 169.288546 145.42955 174.11674 140.289628 175.323789 145.127202 171.099119 141.499022 174.11674 143.313112 175.927313 148.150685 172.306167 152.988258 168.081498 156.011742 162.046256 158.430528 161.744493 162.965753 159.933921 167.500978 159.933921 172.338552 160.839207"
            ></path>
            <path
              id="Shape"
              d="M145.731898 149.070485 155.407045 144.544053 159.337573 139.715859 162.361057 134.887665 163.57045 130.361233 163.57045 125.231278"
            ></path>
            <path
              id="Shape"
              d="M151.778865 118.290749 148.150685 118.894273 143.61546 120.704846 138.777886 122.213656 133.637965 121.006608"
            ></path>
            <path
              id="Shape"
              d="M133.033268 120.101322 130.009785 122.213656 122.753425 124.325991 127.28865 124.325991 130.91683 121.30837 135.754403 119.497797 141.499022 118.290749"
            ></path>
            <path
              id="Shape"
              d="M132.126223 116.178414 124.265166 121.30837 122.451076 122.213656 126.986301 118.894273 131.823875 116.480176 136.661448 115.876652 141.196673 114.669604 146.034247 113.462555 151.476517 117.988987"
            ></path>
            <path
              id="Shape"
              d="M152.988258 112.859031 160.244618 117.687225 162.965753 122.515419 160.546967 127.343612 158.732877 132.171806 157.523483 137.301762 157.825832 136.698238"
            ></path>
            <path
              d="M161.75636,113.462555 L152.081213,108.634361"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M156.011742 103.202643 151.174168 100.788546 144.522505 100.788546 139.98728 100.788546 133.940313 100.185022 129.10274 101.090308"
            ></path>
            <path
              id="Shape"
              d="M127.590998 102.297357 132.126223 101.090308 127.28865 102.900881 126.381605 101.090308 131.219178 98.0726872 136.056751 98.0726872 140.894325 98.0726872 145.731898 98.0726872 150.569472 98.3744493 153.895303 102.599119"
            ></path>
            <path
              id="Shape"
              d="M182.618395 103.504405 187.455969 102.900881 192.59589 102.599119"
            ></path>
            <path
              d="M191.386497,108.332599 L182.920744,113.160793"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M190.177104 116.178414 195.014677 116.178414 202.271037 117.988987"
            ></path>
            <path
              id="Shape"
              d="M201.666341 119.799559 193.805284 122.515419 188.96771 121.30837 184.432485 123.722467"
            ></path>
            <path
              id="Shape"
              d="M187.455969 119.799559 185.037182 124.929515 185.037182 129.757709 186.246575 134.887665 188.363014 140.017621 191.991194 144.845815 193.805284 148.165198 198.642857 149.372247 203.178082 149.674009 208.015656 148.46696 212.550881 146.656388 217.388454 144.242291 225.551859 149.674009"
            ></path>
            <path
              d="M230.691781,123.420705 L235.227006,121.006608"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M239.157534 105.918502 231.296477 110.143172 226.458904 109.539648 225.551859 105.918502 228.575342 102.900881 230.389432 100.486784 226.156556 97.1674009 224.342466 97.469163 226.156556 92.339207 229.482387 87.5110132 228.877691 82.0792952 223.737769 83.8898678"
            ></path>
            <path
              id="Shape"
              d="M229.784736 85.3986784 234.319961 84.19163 235.831703 89.0198238 238.552838 90.5286344 239.157534 87.8127753 243.692759 85.0969163 243.692759 80.8722467 248.530333 82.3810573 250.949119 83.8898678 253.367906 81.1740088"
            ></path>
            <path
              id="Shape"
              d="M253.670254 78.7599119 255.181996 83.5881057 254.577299 90.8303965 253.367906 95.9603524 256.089041 98.3744493 260.926614 97.7709251 267.578278 100.185022"
            ></path>
            <path
              d="M263.64775,99.8832599 L263.64775,94.753304"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M258.205479 92.339207 263.64775 92.9427313 259.717221 97.469163 253.367906 102.900881 254.577299 107.427313 250.344423 109.237885 248.832681 114.367841 253.670254 117.385463 250.646771 120.403084 248.530333 125.231278 249.739726 127.645374 250.949119 123.722467 259.112524 120.704846 263.950098 124.325991"
            ></path>
            <path
              id="Shape"
              d="M259.717221 121.610132 261.228963 126.438326 265.46184 125.231278 264.252446 130.059471 261.228963 132.473568 264.857143 136.094714 264.857143 140.922907 266.066536 146.354626 264.252446 147.259912"
            ></path>
            <path
              d="M269.694716,146.052863 L272.113503,141.22467"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M274.53229 140.017621 270.299413 140.017621 269.09002 137 266.066536 139.112335 266.066536 134.284141"
            ></path>
            <path
              id="Shape"
              d="M260.321918 137 259.717221 136.094714 258.507828 131.26652 256.693738 126.438326"
            ></path>
            <path
              d="M253.065558,127.04185 L254.274951,122.213656"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M253.972603 110.746696 255.181996 113.462555 257.903131 117.385463 252.460861 121.610132 250.042074 126.136564 252.158513 130.964758 257.903131 132.77533 263.345401 132.77533 269.392368 133.982379"
            ></path>
            <path
              d="M282.695695,137.905286 L287.533268,141.526432"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M289.952055 135.792952 294.789628 133.378855 295.999022 133.680617 294.48728 138.207048 293.277886 143.035242 291.766145 147.561674 295.091977 146.354626 298.417808 141.526432 298.417808 136.094714 295.999022 137.905286 299.324853 142.129956 295.394325 146.656388 300.836595 148.46696 304.162427 144.845815"
            ></path>
            <path
              id="Shape"
              d="M300.534247 143.638767 305.674168 138.810573 308.092955 137.603524 307.18591 140.621145 300.836595 143.940529 305.069472 145.751101 308.092955 151.484581"
            ></path>
            <path
              id="Shape"
              d="M290.254403 150.579295 285.41683 150.881057 280.579256 149.975771"
            ></path>
            <path
              id="Shape"
              d="M282.695695 122.515419 281.183953 118.592511 283.60274 114.971366 282.090998 119.497797"
            ></path>
            <path
              id="Shape"
              d="M294.789628 117.988987 291.766145 114.367841 290.556751 118.290749 294.789628 113.462555 289.952055 109.237885 289.347358 107.427313 288.440313 105.013216"
            ></path>
            <path
              id="Shape"
              d="M287.533268 98.3744493 285.719178 99.5814978 288.137965 95.0550661"
            ></path>
            <path
              id="Shape"
              d="M270.601761 86.907489 269.392368 91.7356828 270.601761 96.5638767 269.694716 101.995595 263.345401 108.634361 268.182975 113.462555"
            ></path>
            <path
              id="Shape"
              d="M251.553816 100.788546 246.716243 95.9603524 241.878669 97.7709251"
            ></path>
            <path
              id="Shape"
              d="M237.948141 97.1674009 242.785714 94.1497797 245.204501 95.6585903 246.716243 97.469163 247.320939 105.013216 248.227984 108.634361"
            ></path>
            <path
              id="Shape"
              d="M244.297456 106.220264 246.716243 111.048458 250.949119 116.178414"
            ></path>
            <path
              id="Shape"
              d="M274.229941 123.420705 269.694716 128.248899 262.438356 128.248899 255.181996 125.834802 250.949119 120.704846 245.506849 117.988987 242.483366 119.799559 241.576321 119.799559 237.948141 119.799559 234.622309 113.764317 238.250489 110.746696 237.645793 105.918502 239.157534 101.090308 234.924658 96.8656388 236.738748 92.0374449 237.948141 93.8480176 239.157534 88.7180617 237.948141 83.8898678 240.366928 82.6828194 242.785714 82.0792952 236.738748 82.0792952"
            ></path>
            <path
              id="Shape"
              d="M228.575342 99.2797357 227.063601 102.599119 229.482387 96.2621145 227.668297 91.4339207 222.830724 92.0374449 217.993151 92.0374449"
            ></path>
            <path
              id="Shape"
              d="M217.690802 94.753304 223.133072 98.0726872 221.923679 102.900881 224.342466 103.504405 229.180039 103.806167"
            ></path>
            <path
              id="Shape"
              d="M217.690802 104.409692 213.155577 105.61674 208.318004 106.823789 203.480431 105.61674"
            ></path>
            <path
              id="Shape"
              d="M202.875734 114.669604 208.318004 119.497797 209.527397 121.911894"
            ></path>
            <path
              id="Shape"
              d="M213.155577 111.651982 213.457926 116.178414 213.457926 121.30837 214.969667 126.438326 214.969667 131.26652 216.481409 136.094714"
            ></path>
            <path
              id="Shape"
              d="M215.876712 107.125551 209.829746 101.995595 204.992172 100.788546 200.154599 98.3744493 198.03816 100.788546 193.200587 100.788546 188.363014 100.486784 183.52544 100.788546"
            ></path>
            <path
              id="Shape"
              d="M187.455969 111.048458 182.618395 114.066079 177.780822 114.066079 173.245597 114.669604 168.408023 114.367841 163.57045 113.462555 159.035225 110.143172 153.895303 108.332599 149.05773 106.823789 142.406067 106.522026 137.568493 107.125551 132.73092 107.729075 127.893346 108.936123 123.055773 109.539648 117.915851 111.048458 113.078278 112.255507 108.240705 113.160793 103.403131 112.255507 98.5655577 109.84141"
            ></path>
            <path
              id="Shape"
              d="M83.4481409 129.455947 88.2857143 127.343612 88.890411 131.568282 85.5645793 136.094714 88.5880626 140.621145"
            ></path>
            <path
              id="Shape"
              d="M79.5176125 145.147577 84.3551859 152.993392 88.890411 156.312775 93.7279843 158.123348 98.2632094 158.42511"
            ></path>
            <path
              id="Shape"
              d="M89.1927593 143.940529 86.1692759 140.319383 81.6340509 138.207048 71.6565558 137 66.2142857 135.792952 56.8414873 141.828194 54.4227006 147.863436 55.3297456 141.828194 53.5156556 135.792952 53.2133072 130.662996 52.3062622 125.834802 51.7015656 121.006608 56.8414873 117.385463 56.8414873 112.859031 58.0508806 106.823789 63.4931507 103.504405 63.4931507 98.6762115 65.0048924 93.8480176 60.167319 92.339207 63.1908023 87.5110132 61.981409 85.3986784 63.1908023 81.4757709 61.6790607 76.6475771 58.9579256 75.4405286 59.260274 73.6299559 59.260274 68.8017621 61.6790607 63.9735683 56.5391389 61.5594714 55.9344423 56.4295154 57.7485323 52.80837 58.353229 49.1872247 62.5861057 48.5837004 62.5861057 43.7555066 58.9579256 41.9449339 61.074364 38.3237885 64.702544 37.1167401 65.3072407 32.5903084 67.1213307 30.7797357 67.1213307 26.253304 66.5166341 21.4251101 65.9119374 16.5969163 69.5401174 12.3722467 69.2377691 9.05286344 72.8659491 7.84581498 73.4706458 3.01762115 74.9823875 1.20704846"
            ></path>
            <path
              id="Shape"
              d="M78.9129159 3.62114537 83.7504892 1.81057269 86.1692759 3.62114537 84.3551859 8.44933921 84.0528376 13.5792952 87.0763209 14.1828194 87.9833659 21.7268722 86.7739726 26.253304 89.797456 31.0814978 87.9833659 35.9096916 90.704501 32.8920705"
            ></path>
            <path
              id="Shape"
              d="M74.9823875 27.7621145 73.7729941 21.123348 74.0753425 15.9933921 71.6565558 15.0881057 67.4236791 18.7092511"
            ></path>
            <path
              id="Shape"
              d="M63.4931507 13.5792952 63.4931507 18.1057269 64.0978474 22.9339207 63.795499 27.7621145 66.8189824 30.1762115 71.6565558 28.3656388"
            ></path>
            <path
              d="M54.4227006,23.5374449 L49.8874755,24.1409692"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M52.3062622 28.6674009 53.2133072 29.2709251 58.6555773 34.0991189 55.6320939 40.1343612 57.446184 46.4713656 55.0273973 51.2995595 58.0508806 54.6189427 64.702544 46.7731278 69.2377691 48.5837004 72.2612524 54.0154185 75.2847358 58.2400881"
            ></path>
            <path
              d="M74.3776908,55.5242291 L69.5401174,59.7488987"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M78.9129159 59.7488987 73.7729941 65.784141 68.9354207 65.784141 61.6790607 68.5 60.7720157 60.654185 59.5626223 59.4471366 63.795499 53.1101322 64.702544 48.5837004 66.2142857 43.7555066 66.2142857 38.3237885 63.1908023 34.0991189 62.5861057 29.5726872 59.8649706 24.7444934 59.8649706 23.5374449 61.3767123 19.3127753 59.8649706 14.4845815 57.446184 16.2951542"
            ></path>
            <path
              id="Shape"
              d="M58.9579256 11.7687225 54.4227006 15.69163 55.6320939 10.8634361 53.2133072 5.73348018"
            ></path>
            <path
              id="Shape"
              d="M50.4921722 2.41409692 50.4921722 7.24229075 52.0039139 10.8634361 59.260274 8.14757709"
            ></path>
            <path
              id="Shape"
              d="M53.8180039 7.24229075 60.7720157 6.03524229 62.5861057 1.20704846"
            ></path>
            <path
              id="Shape"
              d="M57.446184 0.905286344 55.3297456 5.43171806 59.5626223 7.54405286 57.1438356 12.3722467 58.0508806 13.8810573 57.446184 18.407489 57.7485323 23.2356828 52.6086106 22.0286344 53.8180039 24.1409692 56.8414873 26.8568282 51.7015656 29.8744493 51.7015656 35.0044053 52.6086106 39.8325991 51.3992172 44.660793 50.1898239 49.4889868 52.6086106 54.3171806 52.0039139 59.1453744 52.3062622 63.9735683 55.3297456 67.2929515 54.7250489 71.8193833 48.6780822 74.8370044 50.4921722 76.9493392 51.3992172 80.2687225 46.5616438 82.0792952 45.0499022 82.3810573 40.2123288 87.2092511 37.7935421 88.1145374 36.2818004 92.9427313 38.0958904 96.2621145 37.4911937 101.693833 36.2818004 105.918502 37.4911937 105.013216 40.5146771 97.1674009"
            ></path>
            <path
              id="Shape"
              d="M40.5146771 96.5638767 41.4217221 92.0374449 36.5841487 91.7356828"
            ></path>
            <path
              id="Shape"
              d="M34.7700587 89.623348 39.6076321 86.907489 43.8405088 91.7356828 43.2358121 96.5638767 45.0499022 105.013216 42.0264188 105.918502 44.7475538 111.048458 42.9334638 115.876652 43.5381605 120.704846 37.1888454 124.929515 34.7700587 129.757709 34.165362 134.585903 38.7005871 141.828194 38.0958904 146.656388 41.1193738 152.69163 40.8170254 156.011013 37.7935421 158.42511 36.8864971 163.253304 36.2818004 168.081498 36.2818004 172.909692"
            ></path>
            <path
              d="M39.9099804,173.513216 L36.8864971,167.779736"
              id="Shape"
            ></path>
            <path
              d="M44.1428571,174.418502 L48.9804305,174.418502"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M45.0499022 176.832599 45.0499022 181.660793 47.1663405 182.566079 48.6780822 187.394273 44.1428571 189.80837 46.2592955 194.334802"
            ></path>
            <path
              id="Shape"
              d="M50.1898239 194.334802 51.3992172 189.506608 55.3297456 197.352423 55.0273973 201.878855 56.2367906 206.405286 56.8414873 210.931718 64.0978474 207.310573 71.3542074 208.517621 74.3776908 211.535242 81.0293542 213.647577"
            ></path>
            <path
              id="Shape"
              d="M79.8199609 207.612335 78.6105675 208.517621 78.6105675 213.044053 76.7964775 214.251101 75.2847358 219.079295 74.6800391 223.907489 75.2847358 228.735683 74.6800391 233.563877 72.8659491 238.39207 72.2612524 243.522026 72.2612524 248.651982 71.6565558 253.178414 72.5636008 254.385463 70.1448141 259.213656 65.9119374 263.740088 65.9119374 263.740088 70.1448141 258.911894 71.6565558 253.178414 75.2847358 256.196035 81.3317025 254.687225 89.1927593 252.273128 89.1927593 253.480176"
            ></path>
            <path
              id="Shape"
              d="M65.609589 213.647577 67.4236791 208.819383 71.3542074 202.180617 70.1448141 197.654185 63.795499 204.292952"
            ></path>
            <path
              id="Shape"
              d="M77.4011742 200.068282 76.7964775 194.636564 75.8894325 193.731278 74.0753425 189.80837 77.7035225 184.980176 79.2152642 183.471366 74.3776908 189.204846 73.1682975 181.660793 73.4706458 176.530837 77.7035225 172.004405 78.0058708 167.176211 77.0988258 162.046256 75.8894325 157.218062 71.9589041 152.389868"
            ></path>
            <path
              id="Shape"
              d="M69.2377691 157.218062 67.4236791 156.011013 64.4001957 158.726872 62.888454 155.709251 58.0508806 160.235683 54.1203523 156.011013 54.1203523 151.484581"
            ></path>
            <path
              id="Shape"
              d="M47.1663405 150.579295 49.8874755 152.389868 49.2827789 157.519824 47.7710372 162.046256"
            ></path>
            <path
              id="Shape"
              d="M48.6780822 161.744493 47.4686888 152.993392 51.3992172 145.751101 46.2592955 145.751101"
            ></path>
            <path
              id="Shape"
              d="M63.1908023 147.561674 68.3307241 148.165198 67.7260274 143.638767 71.9589041 140.621145 67.1213307 138.207048"
            ></path>
            <path
              id="Shape"
              d="M71.9589041 144.544053 71.9589041 149.070485 71.3542074 153.898678 68.9354207 159.028634 71.6565558 161.140969 73.4706458 164.762115 76.4941292 169.590308 75.2847358 173.814978 79.8199609 175.022026 79.2152642 179.85022 75.5870841 181.057269 74.3776908 186.187225 70.1448141 190.713656 73.7729941 196.748899 67.4236791 200.370044 64.4001957 205.198238 64.4001957 211.837004 63.795499 216.665198 65.609589 221.493392"
            ></path>
            <path
              id="Shape"
              d="M50.1898239 216.665198 46.5616438 211.23348 49.8874755 210.931718 51.3992172 209.72467 52.3062622 205.198238 52.9109589 200.370044 52.9109589 194.636564"
            ></path>
            <path
              id="Shape"
              d="M47.1663405 203.689427 47.7710372 206.103524 50.1898239 212.742291 49.5851272 217.570485"
            ></path>
            <path
              d="M51.3992172,222.096916 L46.5616438,222.096916"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M56.5391389 211.535242 55.0273973 206.707048 55.0273973 203.689427 54.1203523 198.861233 52.6086106 194.334802 52.3062622 189.80837 51.0968689 186.790749 48.9804305 181.962555 42.9334638 184.376652"
            ></path>
            <path
              id="Shape"
              d="M36.5841487 185.5837 40.2123288 181.962555 38.0958904 187.092511 39.3052838 191.920705 44.1428571 193.731278 45.3522505 198.559471 44.1428571 200.671806 46.5616438 203.387665 45.9569472 207.914097 37.7935421 209.121145 37.7935421 214.552863 37.4911937 219.984581 40.5146771 227.226872 40.5146771 229.640969"
            ></path>
            <path
              id="Shape"
              d="M52.3062622 228.433921 51.7015656 223.605727 53.5156556 219.079295 52.0039139 214.251101 50.1898239 211.535242 47.1663405 213.044053 40.2123288 207.008811 37.7935421 202.482379 35.3747554 192.222467 30.8395303 198.559471 30.8395303 195.843612 32.6536204 194.03304 35.072407 189.204846 33.258317 184.376652 28.4207436 182.264317"
            ></path>
            <path
              id="Shape"
              d="M26.3043053 188.299559 31.1418787 188.299559 36.5841487 187.696035 36.5841487 190.713656 39.6076321 194.636564 42.6311155 197.654185"
            ></path>
            <path
              id="Shape"
              d="M38.0958904 197.352423 39.3052838 202.180617 39.6076321 207.008811"
            ></path>
            <path
              d="M37.1888454,201.577093 L34.4677104,196.748899"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M24.4902153 200.068282 26.909002 204.594714 29.3277886 209.422907 28.723092 214.251101 30.537182 216.363436 32.0489237 219.381057 30.8395303 223.303965 35.6771037 224.812775 36.2818004 229.640969 36.5841487 234.469163"
            ></path>
            <path
              id="Shape"
              d="M28.1183953 234.770925 27.5136986 229.640969 20.2573386 232.65859"
            ></path>
            <path
              d="M21.1643836,238.693833 L21.7690802,243.522026"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M17.2338552 244.125551 15.4197652 241.711454 14.2103718 236.88326 14.2103718 232.356828 12.3962818 227.226872 3.3258317 221.795154 2.41878669 216.96696 1.20939335 212.138767 2.41878669 207.310573 3.3258317 202.180617 5.13992172 197.352423 6.04696673 192.524229 7.25636008 187.997797 9.37279843 189.204846 10.5821918 186.187225 10.5821918 181.359031 11.1868885 176.530837 10.5821918 171.702643 11.1868885 166.572687 10.8845401 161.744493 10.5821918 156.614537 8.16340509 152.993392 10.2798434 150.277533 11.4892368 145.449339 12.6986301 140.621145 13.9080235 135.792952 12.0939335 130.964758 10.8845401 125.834802 10.2798434 122.213656 8.46575342 117.385463 7.55870841 112.859031 9.37279843 108.030837 8.16340509 103.202643 5.7446184 100.788546"
            ></path>
            <path
              id="Shape"
              d="M3.02348337 108.936123 4.53522505 106.522026 3.02348337 111.35022 0.90704501 115.876652 0.90704501 120.704846 3.62818004 121.610132 4.23287671 126.438326 4.83757339 131.26652 3.02348337 135.792952 7.25636008 128.550661 7.25636008 133.378855 8.46575342 141.22467 10.8845401 147.259912 10.2798434 146.354626"
            ></path>
            <path
              id="Shape"
              d="M8.16340509 145.449339 7.86105675 143.035242 9.97749511 138.207048"
            ></path>
            <path
              d="M13.9080235,142.73348 L12.6986301,147.561674"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M16.0244618 143.638767 16.0244618 148.46696 11.7915851 156.614537 16.6291585 154.803965"
            ></path>
            <path
              d="M13.3033268,164.15859 L18.1409002,164.762115"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M12.3962818 165.063877 15.4197652 172.306167 15.4197652 177.134361"
            ></path>
            <path
              id="Shape"
              d="M19.9549902 174.11674 23.5831703 181.359031 21.7690802 186.187225 25.3972603 193.429515 26.909002 197.955947 25.3972603 202.784141 26.6066536 207.310573 26.909002 212.138767 26.0019569 216.665198 27.2113503 221.493392 31.444227 224.511013 32.6536204 229.339207 33.5606654 234.167401 32.9559687 237.486784"
            ></path>
            <path
              id="Shape"
              d="M32.6536204 221.493392 27.2113503 228.735683 22.6761252 226.321586 22.0714286 224.511013 21.7690802 219.682819 22.0714286 214.854626 21.1643836 210.026432 21.1643836 204.896476 18.7455969 200.068282 16.3268102 197.955947 14.8150685 200.370044"
            ></path>
            <path
              id="Shape"
              d="M19.6526419 200.671806 24.4902153 194.938326 30.8395303 191.317181"
            ></path>
            <path
              id="Shape"
              d="M16.3268102 193.127753 12.6986301 195.54185 5.13992172 195.54185"
            ></path>
            <path
              id="Shape"
              d="M0.90704501 199.76652 3.3258317 197.654185 3.93052838 192.825991 4.53522505 188.299559 3.3258317 183.471366 5.13992172 176.832599 5.7446184 172.004405 3.93052838 169.590308 5.44227006 164.762115 3.62818004 159.933921 0.604696673 157.821586"
            ></path>
            <path
              id="Shape"
              d="M5.13992172 152.088106 9.97749511 155.407489 11.4892368 163.856828 13.3033268 169.89207"
            ></path>
            <path
              d="M8.46575342,172.909692 L7.25636008,177.737885"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M5.44227006 171.400881 5.13992172 164.15859 0.302348337 168.081498"
            ></path>
            <path
              d="M6.34931507,184.678414 L6.04696673,189.506608"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M12.6986301 193.429515 11.7915851 198.257709 10.8845401 203.991189 11.4892368 208.819383 13.3033268 209.72467 16.9315068 210.931718 17.2338552 215.45815 16.0244618 217.268722 19.0479452 220.889868 21.7690802 226.92511 26.0019569 228.132159 23.5831703 232.960352 26.0019569 233.865639 29.630137 237.486784 29.630137 242.314978 29.9324853 246.539648 26.3043053 247.143172"
            ></path>
            <path
              id="Shape"
              d="M20.5596869 258.30837 22.3737769 253.480176 22.6761252 248.651982 24.7925636 243.823789 24.7925636 238.995595 22.6761252 234.469163 20.2573386 229.640969 14.8150685 228.132159"
            ></path>
            <path
              id="Shape"
              d="M11.4892368 219.984581 13.0009785 228.433921 10.5821918 238.693833 11.7915851 243.220264 13.0009785 248.048458 13.0009785 252.876652 14.2103718 257.403084 17.8385519 259.213656"
            ></path>
            <path
              id="Shape"
              d="M14.2103718 269.77533 10.5821918 270.680617 12.3962818 270.680617 12.3962818 265.852423 16.6291585 262.231278 11.7915851 258.30837 8.76810176 253.480176 7.55870841 246.84141 7.55870841 241.10793 7.55870841 236.279736 8.16340509 232.65859 8.16340509 227.830396 9.37279843 223.002203 8.76810176 218.174009 6.95401174 213.345815 6.34931507 215.759912"
            ></path>
            <path
              id="Shape"
              d="M15.1174168 214.552863 20.5596869 217.570485 18.7455969 217.570485 24.7925636 219.984581 30.537182 220.588106 32.351272 216.363436"
            ></path>
            <path
              id="Shape"
              d="M27.816047 211.23348 24.1878669 209.121145 19.3502935 201.27533 22.9784736 194.03304 22.6761252 189.204846 19.0479452 186.790749 19.3502935 185.885463 20.5596869 181.057269 20.5596869 176.229075 19.6526419 173.814978 17.8385519 168.986784 17.2338552 164.15859 16.3268102 161.140969 17.5362035 155.709251 16.3268102 153.295154 17.8385519 148.165198 19.0479452 143.638767 17.2338552 138.810573 17.8385519 133.982379 17.2338552 129.154185 17.2338552 124.024229 15.4197652 119.497797 14.2103718 114.669604 17.2338552 109.84141 16.6291585 104.711454 15.4197652 99.2797357 16.0244618 94.753304 15.7221135 89.9251101 12.6986301 84.4933921 8.16340509 81.1740088 9.0704501 89.623348"
            ></path>
            <path
              id="Shape"
              d="M14.5127202 78.7599119 18.7455969 83.5881057 17.2338552 88.4162996 16.9315068 92.9427313 18.1409002 97.7709251 18.1409002 102.900881 18.7455969 107.729075 18.7455969 115.876652 19.3502935 121.006608 18.7455969 125.834802 18.1409002 127.04185"
            ></path>
            <path
              id="Shape"
              d="M21.1643836 131.870044 19.3502935 136.396476 19.3502935 141.22467 19.3502935 145.751101 18.7455969 150.579295 19.6526419 155.407489 19.0479452 161.140969"
            ></path>
            <path
              id="Shape"
              d="M28.723092 159.933921 33.5606654 156.312775 35.6771037 160.537445 32.6536204 163.253304"
            ></path>
            <path
              id="Shape"
              d="M38.7005871 153.295154 42.9334638 149.070485 42.3287671 144.544053 42.9334638 136.094714 43.8405088 131.26652 44.1428571 130.059471 44.1428571 127.04185"
            ></path>
            <path
              id="Shape"
              d="M44.4452055 118.894273 49.2827789 119.497797 52.6086106 119.497797 58.0508806 123.420705 60.167319 120.101322 65.9119374 121.911894 68.9354207 122.817181"
            ></path>
            <path
              id="Shape"
              d="M70.1448141 125.834802 69.8424658 130.662996 73.4706458 137.301762"
            ></path>
            <path
              id="Shape"
              d="M73.7729941 146.052863 74.3776908 150.881057 75.2847358 155.709251 73.7729941 158.726872 74.9823875 163.856828 71.0518591 167.477974 71.6565558 172.60793 72.8659491 176.229075 70.7495108 181.057269 70.7495108 185.885463 67.4236791 191.618943 72.8659491 190.713656"
            ></path>
            <path
              id="Shape"
              d="M63.4931507 190.110132 62.5861057 184.980176 64.4001957 180.151982 66.2142857 176.530837 66.5166341 171.400881 68.3307241 166.270925 67.4236791 162.348018 65.3072407 157.519824 68.0283757 152.389868 63.1908023 146.95815 63.795499 142.129956 61.981409 139.414097 63.795499 134.585903 65.609589 134.585903 64.4001957 128.852423 63.795499 124.024229 60.7720157 122.213656 63.795499 119.497797 62.5861057 119.799559 61.3767123 116.781938 60.7720157 111.651982 58.9579256 108.634361 59.260274 102.900881 61.6790607 95.6585903 61.074364 88.1145374 55.9344423 88.1145374 51.3992172 88.1145374 46.5616438 91.7356828"
            ></path>
            <path
              id="Shape"
              d="M48.0733855 94.1497797 46.8639922 98.9779736 47.4686888 103.504405 46.5616438 108.332599 47.1663405 113.160793 48.9804305 115.273128 45.9569472 117.687225 46.5616438 122.515419 50.1898239 128.550661 51.3992172 129.154185 50.1898239 134.284141 52.0039139 136.094714 51.3992172 140.621145 53.2133072 145.449339 52.6086106 150.277533 52.0039139 154.803965 50.4921722 153.295154"
            ></path>
            <path
              id="Shape"
              d="M44.1428571 158.123348 37.7935421 159.028634 33.5606654 152.993392 30.537182 145.147577 29.9324853 140.319383 26.3043053 136.094714 25.6996086 128.852423 26.0019569 124.024229 24.1878669 117.385463 24.7925636 114.669604 27.2113503 108.030837 21.1643836 102.297357 23.5831703 101.090308 24.7925636 95.6585903 25.6996086 90.8303965 22.6761252 87.2092511 23.5831703 82.3810573 24.7925636 77.5528634 23.5831703 72.4229075 19.9549902 69.1035242 19.0479452 64.2753304 18.4432485 59.7488987 18.4432485 54.9207048 14.2103718 50.6960352 16.6291585 47.9801762 14.2103718 46.7731278 15.1174168 41.9449339 15.7221135 37.4185022 16.0244618 32.5903084 11.4892368 29.2709251 15.4197652 25.6497797 14.8150685 20.8215859 13.0009785 16.2951542 9.37279843 11.4669604 3.93052838 9.35462555"
            ></path>
            <path
              id="Shape"
              d="M4.83757339 0.301762115 3.62818004 5.12995595 4.83757339 9.95814978 1.20939335 13.277533 4.23287671 10.8634361 10.2798434 11.1651982 13.3033268 10.561674"
            ></path>
            <path
              id="Shape"
              d="M16.6291585 15.3898678 13.9080235 13.277533 13.0009785 10.2599119 10.5821918 5.43171806 8.76810176 0.603524229"
            ></path>
            <path
              id="Shape"
              d="M7.25636008 0.301762115 8.46575342 5.12995595 7.86105675 9.65638767 7.25636008 14.4845815 10.8845401 16.2951542 8.16340509 21.123348 8.76810176 25.9515419 7.86105675 30.7797357 7.25636008 35.9096916 5.7446184 41.0396476 4.53522505 45.8678414 8.76810176 47.0748899 11.4892368 52.80837 13.3033268 57.6365639 13.6056751 62.1629956 13.6056751 66.9911894 16.6291585 72.7246696 16.3268102 73.9317181 13.3033268 75.7422907 13.0009785 80.8722467 13.6056751 85.7004405 11.7915851 91.1321586 11.1868885 95.9603524 9.67514677 98.9779736 11.7915851 103.806167 13.6056751 105.013216 17.8385519 110.746696 20.8620352 112.557269 19.9549902 117.687225 21.7690802 120.101322 22.9784736 125.231278 23.5831703 129.757709 24.1878669 133.982379 26.0019569 138.508811 32.6536204 142.73348 33.5606654 146.354626 35.3747554 152.088106 34.4677104 156.9163 31.1418787 160.537445 33.5606654 165.365639 30.2348337 168.081498 31.7465753 172.909692 28.4207436 181.057269 31.444227 185.885463 31.444227 190.411894 28.4207436 195.240088 31.7465753 199.76652 31.1418787 204.594714 34.165362 206.405286 33.8630137 211.23348 37.4911937 216.061674 36.8864971 220.889868 39.3052838 226.019824 38.0958904 230.848018 44.1428571 231.451542 39.0029354 232.65859 39.6076321 232.65859 42.3287671 234.167401 47.1663405 232.055066 52.0039139 230.244493"
            ></path>
            <path
              id="Shape"
              d="M31.7465753 236.581498 30.537182 231.753304 28.1183953 228.735683 26.3043053 224.209251 25.0949119 219.381057 24.7925636 214.552863 23.5831703 209.72467"
            ></path>
            <path d="M5.7446184,205.5 L1.81409002,206.103524" id="Shape"></path>
            <path
              id="Shape"
              d="M12.0939335 194.938326 13.3033268 199.76652 13.9080235 204.594714 14.2103718 209.72467 11.1868885 216.363436 12.3962818 221.19163 13.9080235 226.019824 15.1174168 231.14978 16.6291585 236.279736 16.6291585 241.10793 20.5596869 237.486784"
            ></path>
            <path
              id="Shape"
              d="M13.3033268 239.297357 9.97749511 236.279736 6.34931507 241.711454 5.13992172 241.409692 3.93052838 234.770925"
            ></path>
            <path
              id="Shape"
              d="M1.81409002 225.718062 3.62818004 230.546256 1.51174168 233.563877 0.90704501 236.581498 3.3258317 243.823789 3.93052838 247.143172 8.76810176 252.57489 8.76810176 256.196035 13.6056751 259.213656"
            ></path>
            <path
              id="Shape"
              d="M9.67514677 268.870044 9.97749511 264.343612 3.3258317 264.04185"
            ></path>
            <path
              d="M0.604696673,257.704846 L5.44227006,254.988987"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M0.604696673 245.936123 3.02348337 250.764317 3.93052838 253.178414 0.90704501 258.006608 1.51174168 263.136564 1.51174168 269.171806"
            ></path>
            <path
              id="Shape"
              d="M18.4432485 267.361233 13.0009785 265.852423 8.16340509 271.887665 2.41878669 269.77533 4.23287671 271.284141 6.04696673 266.455947 5.13992172 265.248899 6.04696673 260.420705 7.86105675 255.290749 7.25636008 250.462555 3.93052838 246.237885 2.11643836 241.409692 1.51174168 236.581498 5.13992172 235.374449 6.34931507 230.546256 4.23287671 226.019824 6.65166341 217.570485 6.04696673 212.742291 6.95401174 208.819383 9.37279843 208.215859 8.76810176 203.387665 9.0704501 198.559471 9.37279843 193.429515 11.7915851 192.222467 16.6291585 190.713656 14.5127202 185.5837 13.6056751 180.755507 14.2103718 175.927313 13.6056751 171.400881 8.76810176 168.986784 6.34931507 164.460352 8.46575342 160.537445 6.04696673 155.709251 6.04696673 150.881057 6.04696673 146.354626 6.04696673 141.526432 6.95401174 136.698238 6.34931507 133.680617 10.5821918 131.26652 9.37279843 126.438326 12.6986301 124.627753 8.46575342 121.911894 6.95401174 121.911894 6.04696673 117.385463 4.83757339 112.859031 6.95401174 111.048458 7.55870841 108.634361 6.34931507 103.806167 9.0704501 99.8832599 5.44227006 96.5638767 7.86105675 95.3568282 8.16340509 90.2268722 6.95401174 85.3986784 6.04696673 80.5704846 5.44227006 75.7422907 1.81409002 73.3281938 1.81409002 68.5 5.44227006 63.9735683 5.44227006 59.1453744 4.53522505 54.9207048 2.72113503 52.80837 1.20939335 46.7731278 1.81409002 42.246696 1.20939335 37.4185022 2.41878669 34.4008811 2.41878669 29.5726872 0.90704501 27.7621145"
            ></path>
            <path
              id="Shape"
              d="M10.5821918 29.5726872 10.5821918 30.7797357 10.5821918 33.7973568"
            ></path>
            <path
              d="M10.2798434,40.1343612 L5.44227006,40.7378855"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M5.7446184 39.530837 8.46575342 40.4361233 11.1868885 45.2643172 11.7915851 50.092511"
            ></path>
            <path
              d="M11.4892368,49.4889868 L6.65166341,44.660793"
              id="Shape"
            ></path>
            <path
              d="M5.44227006,48.2819383 L10.2798434,51.2995595"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M2.41878669 51.6013216 3.62818004 48.5837004 7.86105675 56.4295154 9.0704501 61.2577093 9.97749511 63.0682819 11.1868885 67.8964758 8.46575342 72.7246696 10.5821918 75.7422907 9.37279843 80.5704846 11.1868885 83.2863436 11.1868885 88.4162996 12.3962818 92.9427313 12.0939335 100.185022"
            ></path>
            <path
              id="Shape"
              d="M19.9549902 97.7709251 25.6996086 98.6762115 24.1878669 103.202643 24.7925636 108.030837 19.3502935 114.367841"
            ></path>
            <path
              d="M13.0009785,111.953744 L13.0009785,119.196035"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M8.76810176 114.066079 10.5821918 116.480176 13.3033268 121.30837 15.1174168 126.136564 13.3033268 133.378855 19.6526419 133.982379 26.3043053 141.828194 27.5136986 146.354626 27.2113503 151.182819 26.909002 156.011013 28.723092 160.839207 28.723092 165.667401 24.7925636 170.797357"
            ></path>
            <path
              id="Shape"
              d="M13.9080235 191.920705 15.7221135 196.748899 15.4197652 201.577093 16.9315068 203.991189 18.7455969 209.121145 18.7455969 213.949339 17.5362035 218.777533 18.1409002 223.605727 19.3502935 226.019824 17.5362035 230.848018 18.1409002 235.676211 20.5596869 236.279736 19.3502935 241.10793 18.7455969 245.936123 19.6526419 250.764317 21.4667319 254.385463 26.6066536 247.746696"
            ></path>
            <path
              id="Shape"
              d="M13.0009785 240.504405 15.4197652 245.634361 17.5362035 250.462555 19.0479452 255.290749 19.6526419 259.817181 19.6526419 264.343612 13.9080235 267.662996"
            ></path>
            <path
              id="Shape"
              d="M73.1682975 216.061674 74.3776908 211.23348 75.2847358 206.405286 75.2847358 201.577093 69.8424658 202.482379 69.8424658 209.422907 72.8659491 204.896476 65.609589 199.464758 65.609589 194.938326 65.9119374 190.110132 68.9354207 185.5837 68.9354207 180.755507 66.5166341 179.548458"
            ></path>
            <path
              id="Shape"
              d="M73.1682975 175.927313 67.1213307 177.134361 65.3072407 172.909692 63.4931507 172.60793 63.795499 167.779736 59.5626223 164.460352"
            ></path>
            <path
              id="Shape"
              d="M56.2367906 166.270925 55.6320939 170.797357 55.6320939 175.927313 57.446184 178.039648 62.2837573 183.169604 67.1213307 180.151982"
            ></path>
            <path
              id="Shape"
              d="M58.353229 179.85022 58.353229 175.022026 57.1438356 170.193833"
            ></path>
            <path
              id="Shape"
              d="M89.4951076 145.147577 84.9598826 140.319383 81.3317025 135.491189 76.7964775 129.757709 75.5870841 124.929515 74.9823875 120.101322 75.2847358 115.273128 69.8424658 107.427313"
            ></path>
            <path
              d="M62.2837573,94.1497797 L57.446184,92.0374449"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M57.446184 92.339207 59.8649706 97.1674009 56.5391389 98.3744493 55.9344423 103.202643 54.1203523 107.729075 54.1203523 111.35022 55.9344423 113.764317 56.8414873 107.729075 61.074364 107.729075 69.2377691 114.066079 76.4941292 109.84141 81.3317025 111.048458 86.1692759 114.367841"
            ></path>
            <path
              id="Shape"
              d="M58.353229 101.39207 53.2133072 104.409692 48.9804305 114.367841 44.1428571 116.480176"
            ></path>
            <path
              id="Shape"
              d="M31.1418787 115.876652 31.7465753 115.273128 29.0254403 119.799559 29.3277886 124.627753 30.537182 126.438326 29.630137 129.455947"
            ></path>
            <path
              id="Shape"
              d="M21.7690802 132.473568 23.8855186 137.301762 23.5831703 143.337004 24.7925636 148.165198 23.8855186 152.389868 24.4902153 157.218062 24.4902153 162.348018 20.8620352 168.081498 17.5362035 169.89207 20.5596869 171.400881"
            ></path>
            <path
              id="Shape"
              d="M34.7700587 136.698238 35.9794521 131.870044 36.5841487 127.04185 31.1418787 126.438326 30.537182 119.497797"
            ></path>
            <path
              id="Shape"
              d="M42.3287671 110.143172 38.7005871 112.859031 38.0958904 108.030837 43.2358121 104.711454 44.4452055 99.8832599 45.3522505 94.753304 45.9569472 89.9251101 44.1428571 85.0969163 40.5146771 81.4757709 42.9334638 75.1387665 43.5381605 69.7070485 45.3522505 69.1035242 44.7475538 64.2753304 42.9334638 62.1629956 41.7240705 57.6365639 42.0264188 53.1101322 33.8630137 53.7136564 33.258317 48.8854626 30.8395303 44.3590308 31.7465753 39.530837 30.537182 34.7026432 30.8395303 29.5726872 30.2348337 24.7444934 24.1878669 23.839207 23.2808219 17.2004405 24.4902153 7.84581498 23.2808219 2.71585903 22.6761252 0.905286344"
            ></path>
            <path
              d="M29.630137,4.2246696 L34.4677104,5.43171806"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M37.7935421 1.20704846 38.7005871 6.03524229 38.7005871 7.24229075 43.2358121 12.0704846 44.4452055 3.92290749 48.9804305 1.50881057 55.6320939 1.20704846"
            ></path>
            <path
              id="Shape"
              d="M49.8874755 2.41409692 48.0733855 7.24229075 48.0733855 12.0704846 47.4686888 16.5969163 47.4686888 21.4251101 47.4686888 25.9515419 48.6780822 30.7797357 48.6780822 35.6079295 48.3757339 40.4361233 52.0039139 47.6784141 50.7945205 49.7907489 55.6320939 42.5484581 57.7485323 37.7202643 62.2837573 36.814978 72.2612524 31.685022 74.0753425 34.7026432"
            ></path>
            <path
              id="Shape"
              d="M84.6575342 47.376652 84.9598826 42.5484581 86.7739726 41.6431718 84.6575342 36.814978 82.2387476 30.4779736 78.9129159 25.6497797 79.8199609 21.123348 79.8199609 16.2951542 78.9129159 11.7687225"
            ></path>
            <path
              id="Shape"
              d="M66.5166341 5.12995595 64.702544 2.71585903 64.702544 7.54405286 70.1448141 11.4669604 74.6800391 7.84581498 83.1457926 11.4669604 87.9833659 9.65638767 94.0303327 1.50881057 96.4491194 12.3722467 101.286693 14.1828194 106.426614 18.1057269 114.59002 21.7268722 119.427593 19.0110132 123.055773 13.5792952 124.265166 12.0704846 126.683953 14.4845815 131.521526 10.8634361 137.870841 9.35462555 144.522505 8.75110132 150.569472 8.14757709 158.430528 6.94052863 163.268102 7.54405286 168.105675 5.73348018 167.500978 1.81057269 168.408023 6.33700441 175.059687 10.561674 184.734834 16.2951542 188.96771 22.0286344 193.805284 28.0638767"
            ></path>
            <path
              id="Shape"
              d="M212.550881 23.839207 212.550881 21.7268722 215.574364 20.2180617 214.969667 23.2356828 216.783757 26.253304 221.621331 22.6321586 222.528376 22.0286344 220.411937 27.1585903 219.807241 32.2885463 218.597847 40.4361233 216.179061 45.2643172 212.853229 49.7907489 214.062622 54.0154185 215.574364 57.3348018 218.597847 57.3348018 223.435421 59.7488987 226.761252 56.4295154"
            ></path>
            <path
              id="Shape"
              d="M231.598826 54.6189427 230.691781 49.7907489 230.087084 46.7731278 224.040117 44.9625551"
            ></path>
            <path
              id="Shape"
              d="M220.714286 44.9625551 225.551859 52.5066079 226.761252 57.3348018"
            ></path>
            <path
              id="Shape"
              d="M235.529354 50.9977974 235.529354 53.1101322 230.691781 45.2643172"
            ></path>
            <path
              id="Shape"
              d="M242.181018 45.2643172 244.599804 43.4537445 249.437378 39.2290749"
            ></path>
            <path
              id="Shape"
              d="M242.181018 39.2290749 240.064579 44.3590308 238.855186 47.376652 240.669276 53.4118943 243.692759 47.376652 246.111546 51.6013216 250.949119 51.9030837 256.089041 53.1101322 258.507828 52.5066079"
            ></path>
            <path
              id="Shape"
              d="M259.717221 63.6718062 264.252446 64.2753304 267.27593 66.6894273 268.787671 71.8193833"
            ></path>
            <path
              id="Shape"
              d="M264.554795 73.6299559 264.554795 68.8017621 260.926614 65.1806167"
            ></path>
            <path
              id="Shape"
              d="M246.413894 58.8436123 247.925636 54.0154185 249.135029 53.4118943 242.483366 59.7488987 236.436399 56.7312775 231.901174 54.6189427 227.063601 53.7136564 225.249511 54.3171806 220.411937 54.9207048 215.876712 56.4295154 218.295499 50.9977974 223.133072 49.1872247 222.830724 43.7555066 224.947162 36.814978 220.411937 36.814978 215.574364 41.0396476"
            ></path>
            <path
              id="Shape"
              d="M218.295499 40.7378855 223.133072 39.2290749 230.994129 39.2290749 233.715264 42.246696"
            ></path>
            <path
              id="Shape"
              d="M241.878669 40.1343612 236.738748 46.1696035 231.901174 49.1872247 231.296477 44.9625551 226.458904 46.1696035 225.854207 50.3942731 221.318982 50.3942731 218.597847 45.2643172 214.969667 47.6784141 215.876712 51.2995595 217.086106 46.4713656"
            ></path>
            <path
              id="Shape"
              d="M219.202544 29.2709251 222.226027 26.8568282 217.388454 21.7268722"
            ></path>
            <path
              id="Shape"
              d="M232.808219 24.7444934 231.901174 26.253304 231.598826 30.7797357 234.924658 30.7797357 239.459883 27.1585903 241.576321 25.3480176 234.924658 30.7797357 240.971624 28.969163 245.809198 30.4779736 248.530333 35.6079295"
            ></path>
            <path
              id="Shape"
              d="M242.785714 29.8744493 237.948141 31.9867841 239.157534 35.0044053 243.995108 33.7973568 247.925636 32.5903084 246.716243 37.4185022 250.344423 39.2290749 244.297456 44.0572687 243.390411 48.8854626 238.250489 53.4118943 238.855186 60.9559471 239.459883 62.4647577"
            ></path>
            <path
              id="Shape"
              d="M218.295499 78.1563877 216.783757 76.9493392 213.760274 72.1211454"
            ></path>
            <path
              id="Shape"
              d="M196.828767 57.938326 203.480431 60.9559471 205.899217 65.784141 208.318004 70.9140969 209.527397 76.0440529 210.736791 80.8722467 211.039139 85.3986784 211.643836 90.2268722 211.643836 95.0550661 211.643836 99.5814978 211.643836 104.409692 211.643836 111.048458 211.946184 115.876652 211.946184 120.704846 212.550881 125.53304 212.550881 130.361233 213.155577 135.189427 214.062622 140.017621 214.062622 135.491189 218.295499 132.473568"
            ></path>
            <path
              id="Shape"
              d="M264.554795 82.3810573 260.321918 86.6057269 265.46184 82.0792952 270.299413 80.5704846 275.136986 84.19163"
            ></path>
            <path
              id="Shape"
              d="M271.508806 80.5704846 275.741683 85.3986784 274.229941 90.5286344 272.415851 92.339207 273.625245 99.8832599"
            ></path>
            <path
              id="Shape"
              d="M302.045988 116.781938 306.883562 112.557269 305.37182 113.764317 304.767123 117.988987 308.697652 119.799559"
            ></path>
            <path
              d="M307.488258,118.592511 L302.650685,117.988987"
              id="Shape"
            ></path>
            <path
              d="M238.552838,24.1409692 L233.715264,24.1409692"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M236.134051 20.2180617 237.645793 18.1057269 238.855186 15.0881057 243.390411 16.5969163"
            ></path>
            <path
              id="Shape"
              d="M245.809198 15.9933921 243.390411 10.8634361 240.064579 9.95814978 235.227006 11.1651982"
            ></path>
            <path
              id="Shape"
              d="M250.344423 6.94052863 249.135029 4.52643172 247.320939 5.12995595 253.367906 3.31938326 250.344423 0.905286344"
            ></path>
            <path
              id="Shape"
              d="M199.549902 8.44933921 200.456947 13.5792952 200.154599 18.407489 201.363992 19.9162996 201.968689 24.7444934 202.875734 29.5726872 204.689824 35.0044053 205.899217 39.530837 208.922701 44.3590308 209.225049 48.8854626 209.225049 53.7136564 209.225049 58.5418502 211.039139 63.0682819 212.248532 67.8964758 212.248532 73.0264317 213.760274 77.8546256 215.574364 82.6828194 215.574364 87.5110132 216.179061 92.6409692 219.807241 93.5462555 217.993151 98.6762115 218.597847 103.806167 221.621331 107.729075 226.458904 107.125551 231.598826 107.125551 234.017613 103.806167 235.227006 100.788546 240.064579 104.409692 245.506849 109.237885 247.623288 104.711454 254.879648 110.444934 260.926614 106.522026 266.066536 106.522026 270.601761 105.61674 275.439335 103.202643"
            ></path>
            <path
              id="Shape"
              d="M188.96771 56.1277533 183.52544 51.6013216 180.199609 46.7731278"
            ></path>
            <path
              id="Shape"
              d="M170.524462 42.8502203 165.686888 41.3414097 160.849315 39.530837 156.011742 39.530837"
            ></path>
            <path
              id="Shape"
              d="M162.965753 40.7378855 169.315068 46.1696035 174.152642 46.1696035 179.89726 45.8678414 185.037182 53.1101322 189.874755 56.1277533 194.40998 57.3348018 199.247554 59.1453744 204.085127 60.3524229 208.922701 69.1035242"
            ></path>
            <path
              d="M193.502935,16.2951542 L189.270059,6.63876652"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M193.200587 3.92290749 193.805284 8.75110132 198.642857 7.54405286"
            ></path>
            <path
              id="Shape"
              d="M195.619374 0.905286344 196.828767 5.73348018 196.526419 10.561674 198.03816 15.3898678 195.619374 20.2180617"
            ></path>
            <path
              id="Shape"
              d="M183.52544 12.0704846 184.432485 7.24229075 184.130137 2.41409692 187.455969 1.20704846 182.618395 1.20704846"
            ></path>
            <path
              id="Shape"
              d="M177.780822 1.50881057 180.199609 6.33700441 180.501957 10.561674 182.618395 15.9933921 187.455969 20.5198238 189.874755 25.3480176 195.317025 29.8744493"
            ></path>
            <path
              id="Shape"
              d="M181.71135 6.63876652 180.501957 1.81057269 182.618395 2.1123348 177.176125 6.94052863"
            ></path>
            <path
              id="Shape"
              d="M175.059687 2.41409692 176.26908 7.54405286 171.431507 7.54405286 175.664384 10.8634361 180.501957 13.8810573"
            ></path>
            <path
              id="Shape"
              d="M188.060665 15.69163 188.665362 10.8634361 189.270059 6.03524229 188.665362 1.20704846"
            ></path>
            <path
              id="Shape"
              d="M189.572407 0.905286344 190.479452 5.73348018 190.479452 9.95814978 193.200587 16.2951542 195.317025 23.839207"
            ></path>
            <path
              id="Shape"
              d="M162.058708 3.92290749 164.175147 2.71585903 162.361057 0.603524229"
            ></path>
            <path
              d="M157.221135,0.603524229 L157.825832,5.43171806"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M155.709393 7.84581498 156.31409 3.01762115 154.5 1.20704846"
            ></path>
            <path
              d="M146.941292,2.71585903 L145.127202,7.54405286"
              id="Shape"
            ></path>
            <path
              d="M138.777886,5.73348018 L137.568493,1.20704846"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M135.754403 0.301762115 137.266145 5.12995595 137.568493 9.95814978 136.3591 13.8810573"
            ></path>
            <path
              id="Shape"
              d="M122.148728 15.9933921 121.544031 10.2599119 122.148728 5.43171806 124.567515 1.81057269 119.125245 0.603524229 114.287671 0.301762115"
            ></path>
            <path
              id="Shape"
              d="M111.566536 1.81057269 112.77593 6.63876652 111.566536 8.14757709 112.473581 12.9757709 113.078278 18.1057269"
            ></path>
            <path
              id="Shape"
              d="M108.845401 19.0110132 108.543053 14.1828194 108.543053 9.05286344"
            ></path>
            <path
              id="Shape"
              d="M112.473581 6.94052863 117.311155 5.12995595 123.358121 6.63876652 128.195695 5.43171806"
            ></path>
            <path
              id="Shape"
              d="M127.590998 0.301762115 127.590998 5.12995595 129.405088 10.2599119 124.869863 15.69163"
            ></path>
            <path
              id="Shape"
              d="M138.475538 42.8502203 133.940313 42.5484581 129.10274 42.5484581 124.265166 42.246696 119.125245 44.3590308 113.985323 46.1696035"
            ></path>
            <path
              id="Shape"
              d="M114.287671 49.1872247 118.520548 46.7731278 115.194716 51.6013216 113.985323 56.4295154 110.96184 61.2577093 110.96184 61.2577093"
            ></path>
            <path
              id="Shape"
              d="M76.4941292 72.1211454 77.7035225 76.9493392 77.7035225 85.0969163"
            ></path>
            <path
              id="Shape"
              d="M65.3072407 89.9251101 63.795499 85.7004405 58.9579256 86.907489 56.2367906 83.8898678 52.6086106 82.9845815 45.9569472 85.3986784 44.4452055 83.5881057 39.6076321 89.3215859"
            ></path>
            <path
              id="Shape"
              d="M43.8405088 81.4757709 46.5616438 76.6475771 48.9804305 71.8193833 49.2827789 66.9911894 45.0499022 63.3700441"
            ></path>
            <path
              id="Shape"
              d="M42.0264188 67.5947137 42.9334638 72.4229075 41.7240705 77.5528634"
            ></path>
            <path
              id="Shape"
              d="M47.7710372 67.8964758 46.5616438 63.0682819 44.1428571 57.0330396 44.1428571 52.2048458 43.2358121 44.9625551 40.2123288 40.1343612 38.7005871 35.3061674 40.2123288 30.4779736 37.7935421 25.6497797 37.1888454 24.1409692"
            ></path>
            <path
              id="Shape"
              d="M36.5841487 27.1585903 36.5841487 31.9867841 38.3982387 31.9867841 40.2123288 37.1167401"
            ></path>
            <path
              id="Shape"
              d="M28.723092 34.0991189 30.8395303 38.9273128 30.2348337 43.7555066 30.2348337 48.8854626 30.537182 51.2995595 31.7465753 54.9207048"
            ></path>
            <path
              id="Shape"
              d="M31.1418787 60.9559471 37.1888454 59.1453744 38.7005871 63.9735683 36.8864971 68.5 35.9794521 70.0088106 42.9334638 68.8017621 44.7475538 71.215859 52.6086106 76.0440529 57.7485323 76.345815 61.074364 74.2334802 62.2837573 77.2511013 63.4931507 81.4757709"
            ></path>
            <path
              id="Shape"
              d="M59.5626223 79.061674 57.446184 83.2863436 58.0508806 82.9845815 59.260274 77.8546256"
            ></path>
            <path
              id="Shape"
              d="M55.0273973 75.4405286 55.0273973 80.2687225 50.1898239 83.8898678 52.0039139 83.8898678 55.3297456 87.5110132 51.0968689 90.5286344 52.9109589 92.339207 53.8180039 96.8656388 50.7945205 101.693833 53.2133072 107.427313 54.7250489 114.971366 52.0039139 119.497797 58.0508806 123.420705 59.8649706 128.248899 59.8649706 134.887665 60.4696673 136.698238 67.1213307 133.680617"
            ></path>
            <path
              id="Shape"
              d="M29.3277886 147.259912 20.8620352 155.105727 20.8620352 152.088106 22.0714286 147.561674 22.0714286 142.73348 22.0714286 137.603524 20.8620352 132.77533"
            ></path>
            <path
              id="Shape"
              d="M17.2338552 120.704846 16.6291585 115.876652 14.5127202 111.048458 6.65166341 112.255507"
            ></path>
            <path
              d="M2.41878669,113.462555 L3.62818004,118.290749"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M2.72113503 125.231278 2.11643836 130.059471 2.41878669 134.887665 4.23287671 139.715859 4.53522505 144.845815 3.93052838 152.088106 2.72113503 156.9163 1.81409002 162.046256 1.81409002 166.874449 1.81409002 171.702643 1.81409002 176.530837 2.41878669 181.359031 3.02348337 186.187225 1.81409002 190.713656 6.34931507 189.506608"
            ></path>
            <path
              id="Shape"
              d="M25.6996086 97.7709251 30.537182 96.5638767 35.3747554 98.3744493"
            ></path>
            <path
              id="Shape"
              d="M28.4207436 87.2092511 32.9559687 77.5528634 32.9559687 82.3810573 29.3277886 81.777533 37.1888454 81.1740088 37.7935421 82.9845815"
            ></path>
            <path
              id="Shape"
              d="M24.1878669 84.7951542 21.4667319 82.0792952 18.7455969 78.1563877 17.5362035 72.7246696 13.9080235 74.5352423 8.76810176 72.7246696 3.93052838 73.9317181"
            ></path>
            <path
              id="Shape"
              d="M0.604696673 77.5528634 1.20939335 77.5528634 1.20939335 82.6828194 2.41878669 83.8898678"
            ></path>
            <path
              d="M16.6291585,69.7070485 L21.7690802,72.1211454"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M13.3033268 63.0682819 15.4197652 64.2753304 15.4197652 59.4471366"
            ></path>
            <path
              id="Shape"
              d="M21.4667319 52.5066079 22.6761252 57.3348018 21.7690802 61.8612335 22.0714286 66.6894273 21.4667319 73.6299559 21.1643836 78.4581498 19.3502935 83.8898678 19.3502935 88.7180617 20.5596869 93.5462555 19.9549902 98.3744493 22.9784736 103.806167 29.0254403 103.202643 31.7465753 111.651982 36.5841487 109.237885"
            ></path>
            <path
              id="Shape"
              d="M50.4921722 95.0550661 48.3757339 96.2621145 47.7710372 101.39207 50.7945205 104.10793 51.0968689 102.599119 58.6555773 98.9779736"
            ></path>
            <path
              id="Shape"
              d="M55.6320939 88.4162996 53.8180039 93.2444934 55.6320939 94.4515419 55.0273973 99.2797357 50.7945205 104.10793 48.3757339 108.936123 51.3992172 113.764317 49.8874755 118.592511 48.3757339 123.420705 44.1428571 127.04185 44.7475538 131.26652 44.4452055 136.094714 45.3522505 140.922907 47.7710372 142.129956 47.4686888 146.95815 46.2592955 151.786344 46.2592955 156.9163 45.6545988 160.839207 47.7710372 165.667401 49.5851272 170.193833 51.3992172 170.193833"
            ></path>
            <path
              id="Shape"
              d="M123.962818 147.561674 119.729941 142.73348 117.915851 137.905286 115.799413 132.77533 116.101761 127.645374 116.101761 122.515419 118.520548 117.687225 125.776908 113.160793"
            ></path>
            <path
              d="M42.9334638,24.7444934 L38.0958904,21.123348"
              id="Shape"
            ></path>
            <path
              d="M38.0958904,18.7092511 L40.2123288,23.2356828"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M34.165362 14.4845815 34.165362 19.3127753 34.4677104 23.839207 33.8630137 28.6674009 35.3747554 33.1938326 36.5841487 38.0220264 37.7935421 40.7378855 33.258317 45.5660793"
            ></path>
            <path
              d="M21.1643836,38.3237885 L19.3502935,37.1167401"
              id="Shape"
            ></path>
            <path
              d="M19.9549902,30.7797357 L24.7925636,30.1762115"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M21.7690802 25.3480176 21.7690802 29.8744493 24.4902153 34.7026432 22.6761252 39.2290749 23.5831703 44.3590308 24.1878669 49.1872247 26.0019569 54.0154185 25.0949119 57.6365639 26.3043053 59.7488987 24.7925636 64.5770925 26.909002 70.0088106 29.3277886 72.4229075 28.1183953 75.7422907 31.444227 73.3281938 33.8630137 68.5 38.0958904 64.5770925"
            ></path>
            <path
              d="M13.0009785,44.9625551 L8.76810176,40.4361233"
              id="Shape"
            ></path>
            <path
              id="Shape"
              d="M29.630137 12.3722467 30.8395303 7.54405286 28.4207436 6.33700441"
            ></path>
            <path
              id="Shape"
              d="M31.7465753 0.603524229 35.3747554 5.73348018 32.351272 7.54405286 32.9559687 12.3722467 31.1418787 13.5792952 28.1183953 18.407489 30.2348337 20.8215859 28.4207436 25.0462555 29.0254403 29.8744493 27.2113503 31.3832599 27.2113503 36.2114537 27.816047 40.7378855 26.909002 45.5660793 25.6996086 50.092511 29.0254403 50.3942731 29.0254403 55.222467 29.630137 60.0506608 30.537182 64.8788546 31.1418787 68.1982379 32.0489237 73.0264317 26.909002 76.0440529 26.909002 80.8722467 28.1183953 82.3810573 30.8395303 84.19163 30.8395303 87.2092511 30.8395303 91.7356828 32.6536204 92.339207 32.0489237 94.753304 35.072407 99.5814978 33.8630137 104.409692 32.6536204 105.918502 33.8630137 110.143172 36.8864971 112.859031 37.4911937 115.876652 34.4677104 120.704846 33.258317 122.213656 39.0029354 124.325991 40.2123288 129.154185 40.2123288 134.284141 40.5146771 139.414097 36.2818004 141.828194 35.072407 146.656388 42.0264188 148.46696"
            ></path>
            <path
              id="Shape"
              d="M32.351272 141.526432 31.7465753 136.698238 30.2348337 132.77533 29.630137 128.248899 28.4207436 123.118943 25.3972603 118.894273 28.4207436 114.367841 32.0489237 111.953744 31.444227 107.125551 28.4207436 102.297357 31.444227 101.693833 34.4677104 94.4515419 35.6771037 89.623348 36.5841487 84.7951542 37.7935421 79.9669604 35.3747554 79.3634361 34.165362 76.345815 34.165362 71.5176211 35.072407 66.3876652 32.9559687 63.3700441 31.1418787 58.5418502 36.8864971 57.938326 37.4911937 53.4118943 38.0958904 48.2819383 44.7475538 47.6784141 47.7710372 43.1519824 47.7710372 38.3237885 46.5616438 33.1938326 46.8639922 28.3656388 46.5616438 23.5374449 46.2592955 19.0110132 43.2358121 15.3898678 44.4452055 10.561674 47.4686888 5.43171806"
            ></path>
            <path
              id="Shape"
              d="M44.4452055 0.905286344 43.5381605 1.50881057 43.2358121 6.33700441 45.9569472 11.1651982 44.7475538 15.69163 43.8405088 20.5198238"
            ></path>
            <path
              id="Shape"
              d="M40.2123288 10.2599119 40.2123288 5.12995595 39.0029354 0.301762115"
            ></path>
            <path
              id="Shape"
              d="M68.9354207 0.603524229 66.2142857 5.73348018 65.9119374 10.2599119 67.7260274 15.0881057 66.8189824 19.3127753 68.0283757 23.5374449 69.8424658 28.0638767 73.4706458 29.8744493 69.8424658 34.0991189 72.2612524 35.3061674 72.2612524 39.2290749 71.6565558 44.0572687 72.8659491 48.8854626 78.0058708 52.2048458 74.0753425 55.8259912 74.6800391 58.8436123 74.3776908 64.2753304 73.1682975 69.1035242 71.9589041 73.9317181 68.9354207 77.2511013 69.5401174 82.0792952 67.1213307 86.907489 62.888454 90.2268722 67.7260274 93.8480176 69.2377691 98.6762115 67.4236791 103.504405 65.609589 105.61674 68.0283757 113.160793 68.0283757 121.610132 67.7260274 126.136564 63.4931507 132.171806 56.2367906 135.792952 59.5626223 137.603524 59.260274 142.431718 61.6790607 147.561674 61.981409 149.372247 56.5391389 155.709251 57.7485323 158.123348 59.8649706 159.330396 55.0273973 160.537445 54.1203523 165.667401 54.1203523 166.572687 53.8180039 171.400881 53.5156556 175.927313 49.8874755 177.134361 43.2358121 180.151982 42.3287671 184.678414 39.6076321 189.204846 39.6076321 194.03304 34.165362 196.145374 34.165362 200.973568 38.0958904 204.594714 39.9099804 209.422907 43.5381605 210.629956 45.3522505 215.759912 44.1428571 221.493392 48.9804305 224.511013 48.3757339 229.037445"
            ></path>
          </g>
        </g>
      </svg>
    )
  }
}

export default AboutSVG
